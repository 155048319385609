class Bookings.Schedule
  @MONTHLABELS: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September','October', 'November', 'December']
  @DAYPARTS: ['morning', 'afternoon', 'evening']

  dayPartName = (dayPartNumber) ->
    { 1: 'morning', 2: 'afternoon', 3: 'evening' }[dayPartNumber]

  groupObjectsByProperty = (arrayOfObjects, property) ->
    grouped = {}
    for object in arrayOfObjects
      groupKey = object[property]
      grouped[groupKey] ||= []
      grouped[groupKey].push object
    grouped

  constructor: (options) ->
    @dayPickers                    = $('.datepicker__day__item__number')
    @dayBlocks                     = $('.datepicker__day td')
    @timePickerContent             = $('.timepicker__content tbody')
    @allowTimeZoneChoice           = options.allowTimeZoneChoice
    @allowBookingsDaysInAdvance    = options.allowBookingsDaysInAdvance
    @appointment                   = options.appointment
    @defaultTimeZone               = options.defaultTimeZone
    @supportedTimeZones            = options.supportedTimeZones
    @reservation                   = options.reservation
    @timeZoneIdentifierForTimeZone = options.timeZoneIdentifierForTimeZone
    @detectedTimeZone              = @browserTimeZone()
    @startDate                     = @initialDate()

    @getAppointmentsRequest = null

    if @allowTimeZoneChoice
      if @detectedTimeZone
        @makeDetectedTimeZoneFirstOption()
      $('[data-js-time-zone-selector]').val @timeZone()
      $('[data-js-time-zone-selector]').on 'change', (e) =>
        @selectedTimeZone = $(e.currentTarget).val()
        @resetStartDate()
        @onTimeZoneChange()

    $('.bookings-section--schedule').on "click", ".datepicker__day td:not(.disabled) [data-js-day-button]", (e) =>
      e.preventDefault()
      e.stopPropagation()
      @selectDate(e.currentTarget)

    $('.bookings-section--schedule').on "click", ".module .button--select", (e) =>
      e.preventDefault()
      e.stopPropagation()

      @appointment.timeZone = $(e.currentTarget).data('timeZone')
      @appointment.timeZoneIdentifier = @timeZoneIdentifierForTimeZone @appointment.timeZone
      @appointment.appointmentStart = $(e.currentTarget).data('utcTime')
      @appointment.possibleIds = $(e.currentTarget).data('practitionersAppointments')

      practitionersIdsAvailableInTimeSlot = @fetchAvailablePractitionersIdsArray("#{$(e.currentTarget).data('practitionerIds')}")

      @onSelected practitionersIdsAvailableInTimeSlot

    $('.bookings-section--schedule').on "mousedown keypress", ".button--datepicker-nav.forward", (e) =>
      e.preventDefault()
      e.stopPropagation()
      @showPreviousMonthButton()
      @onMonthUp()

    $('.bookings-section--schedule').on "mousedown keypress", ".button--datepicker-nav.back", (e) =>
      e.preventDefault()
      e.stopPropagation()
      @onMonthDown()

    $('.bookings-section--schedule').on "click", '[data-js-date-deselect]', (e) =>
      e.preventDefault()
      e.stopPropagation()
      @deselectDate()

    $('.bookings-section--schedule').on 'click', '[data-js-skip-date-pickers]', (e) =>
      e.preventDefault()
      e.stopPropagation()
      nextFocusable = document.querySelector('[data-js-date-deselect]:enabled') ||
        document.querySelector('[data-js-back-button]')
      nextFocusable.focus()

    $(document).ready @datepickerSquareTableCells
    $(window).resize @datepickerSquareTableCells

    if !$('html').hasClass('ie8')
      window.addEventListener 'orientationchange', @datepickerSquareTableCells

  makeDetectedTimeZoneFirstOption: ->
    text = $("[data-js-time-zone-selector] option[value=\"#{@detectedTimeZone}\"]").text()
    $("[data-js-time-zone-selector] option[value=\"#{@detectedTimeZone}\"]").remove()
    $('[data-js-time-zone-selector]').prepend($("<option></option>").attr('value', @detectedTimeZone).text(text))

  resetStartDate: ->
    @startDate = @initialDate()

  initialDate: ->
    new Date moment().tz(@timeZoneIdentifier()).startOf('month').format('YYYY/MM/DD')

  disableTimeZoneSelector: ->
    if @allowTimeZoneChoice
      $('[data-js-time-zone-selector]').prop('disabled', true).attr 'aria-disabled', 'true'

  enableTimeZoneSelector: ->
    if @allowTimeZoneChoice
      $('[data-js-time-zone-selector]').prop('disabled', false).removeAttr 'aria-disabled'

  enableOrDisableNextMonthNavigation: ->
    nextMonth = new Date(@startDate.getFullYear(), @startDate.getMonth(), 1).addMonths(1)
    if nextMonth > Date.today().addDays(@allowBookingsDaysInAdvance)
      $('.button--datepicker-nav.forward').prop('disabled', true).attr 'aria-disabled', 'true'
    else
      $('.button--datepicker-nav.forward').prop('disabled', false).removeAttr 'aria-disabled'

  onBack: ->

  fetchAvailablePractitionersIdsArray: (practitionerIdsString) ->
    if practitionerIdsString.indexOf(',') > -1
      practitionerIdsString.split(',')
    else
      [practitionerIdsString]

  browserTimeZone: ->
    try
      detectedTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      $.grep(@supportedTimeZones, (zone, i) -> zone.name == detectedTimeZone)[0]?.label
    catch e
      undefined

  setGroupAppointmentId: () ->
    @appointment.appointmentId = @appointment.possibleIds[@appointment.practitionerId] || null

  showNextMonth: ->
    @showTimePickerHelpMessage()
    @startDate = new Date @beginningOfNextMonth @startDate
    @generateTheCalendar()

  showPreviousMonth: ->
    @showTimePickerHelpMessage()
    @startDate = new Date @beginningOfPreviousMonth @startDate
    if @startDate.getTime() <= @initialDate().getTime() then @resetToInitialDate() else @generateTheCalendar()

  timeZone: ->
    if @allowTimeZoneChoice
      @selectedTimeZone || @appointment.locationTimeZone || @defaultTimeZone
    else
      @appointment.locationTimeZone || @defaultTimeZone

  timeZoneIdentifier: ->
    @timeZoneIdentifierForTimeZone @timeZone()

  showPreviousMonthButton: ->
    $(".button--datepicker-nav.back").prop("disabled", false).removeAttr "aria-disabled"

  hidePreviousMonthButton: ->
    $(".button--datepicker-nav.back").prop("disabled", true).attr "aria-disabled", "true"

  resetToInitialDate: ->
    @resetStartDate()
    @hidePreviousMonthButton()
    @generateTheCalendar()
    if @allowTimeZoneChoice
      $('[data-js-time-zone-selector]').val @timeZone()

  abortCurrentGetAvailableBookingsRequest: ->
    if @getAppointmentsRequest && @getAppointmentsRequest.readyState != 4
      @getAppointmentsRequest.abort()
      $('.timepicker__loading').hide()

  showAvailableBookingsForSelectedDay: (dayBlock)->
    @abortCurrentGetAvailableBookingsRequest()
    @disableTimeZoneSelector()
    $('.timepicker__loading').show()

    facebookPageId = null

    if $('#facebook_page_id').length == 1
      facebookPageId = $('#facebook_page_id').val()

    @getAppointmentsRequest = $.ajax '/bookings/time_slots',
      type: 'GET'
      data:
        appointment_type_id: @appointment.appointmentTypeId
        business_id: @appointment.businessId
        date: [@displayedYear(), @displayedMonth() + 1, @dayNumberOfDayBlock(dayBlock)].join('-')
        facebook_page_id: facebookPageId
        practitioner_ids: @appointment.practitionerId
        reservation_key: @reservation.reservationKey
        time_zone: @timeZone()
      dataType: 'json'
      success: (data) =>
        @refreshAvailableBookingsDataForDayBlock(data, dayBlock)
        $('.timepicker__loading').hide()
        @enableTimeZoneSelector()
        @showTimePickersOfSelectedDayBlock dayBlock
        window.parent.postMessage("cliniko-bookings-resize:#{document.body.offsetHeight}", '*')

  showTimePickersOfSelectedDayBlock: (dayBlock)->
    for dayPart, index in Bookings.Schedule.DAYPARTS
      @showTimePickersOfDayPart dayPart, $(dayBlock).data("#{dayPart}"), index, @dateStringOfDayBlock dayBlock

  showTimePickersOfDayPart: (dayPart, availableBookingsWithinDayPart, numberOfPreviousDayParts, selectedDayDateString)->
    unless availableBookingsWithinDayPart == undefined
      for availableBooking, index in availableBookingsWithinDayPart
        currentRow = @timePickerContent.find('tr')[index]
        if @timePickerContent.find('tr').length == 0 || !currentRow
          @addRowForTimePickers dayPart
          currentRow = @timePickerContent.find('tr')[index]
        else if @numberOfTimePickersInRow(currentRow) < numberOfPreviousDayParts
          @addEmptyPlaceholderForTimePicker currentRow
        @addTimePicker currentRow, availableBooking, selectedDayDateString

  addTimePicker: (row, availableBooking, selectedDayDateString)->
    practitionersAppointments = if availableBooking.practitionersAppointments then JSON.stringify(availableBooking.practitionersAppointments) else ''
    $(row).append("
      <td>
        <button
          aria-label='#{@timePickerAriaLabel(availableBooking)}'
          class='button--select'
          data-practitioners-appointments='#{practitionersAppointments}'
          data-practitioner-ids='#{availableBooking.practitionerIds}'
          data-time-zone='#{availableBooking.time_zone}'
          data-utc-time='#{availableBooking.start_time_utc}'
          tabindex='0'>
          #{@formatTimeAmPm availableBooking.hour, availableBooking.minute}
        </button>
      </td>
    ")

  timePickerAriaLabel: (availableBooking) =>
    [
      availableBooking.hour,
      if availableBooking.minute > 0 then @pad(availableBooking.minute, 2) else '',
      if availableBooking.hour < 12 then 'AM' else 'PM'
    ].join(' ')

  addEmptyPlaceholderForTimePicker: (row)->
    $(row).append('<td></td>')

  numberOfTimePickersInRow: (row)->
    $(row).find('td').length

  addRowForTimePickers: (dayPart)->
    switch dayPart
      when Bookings.Schedule.DAYPARTS[0] then @timePickerContent.append '<tr></tr>'
      when Bookings.Schedule.DAYPARTS[1] then @timePickerContent.append '<tr><td></td></tr>'
      when Bookings.Schedule.DAYPARTS[2] then @timePickerContent.append '<tr><td></td><td></td></tr>'

  generateTheCalendar: ->
    $('.timepicker__loading').hide()
    @showTimePickerHelpMessage()
    $('.datepicker').removeClass('date-selected')
    beginningOfCalendar = @beginningOfCurrentMonth(@startDate)
    @clearDayNumbers()
    @clearCalendarFromAvailableBookingsData()
    @clearTimePickersContent()
    $('.timepicker__date').addClass('visibility-hidden')
    @resetHeaderOfTimePickerContent()
    @markAllDayBlocksAsUnselected()
    @setMonthHeader beginningOfCalendar
    @fillInWithDayNumbers beginningOfCalendar
    @adjustNumberOfDisplayedWeeks(@numberOfRequiredWeeks beginningOfCalendar)

  fillInWithDayNumbers: (beginningOfCalendarDate)->
    firstDay = @dayOfMonth(beginningOfCalendarDate)
    currentMoment = moment().tz(@timeZoneIdentifier())
    monthLabel = Bookings.Schedule.MONTHLABELS[@startDate.getMonth()]
    numberOfDaysInMonth = @numberOfDaysInMonth(@startDate)
    $.each @dayPickers, (index, dayPicker) =>
      $dayPicker = $(dayPicker)
      dayNumber = index + 1 - firstDay
      if dayNumber > 0 && dayNumber <= numberOfDaysInMonth
        $dayPicker.text(dayNumber).data 'numberOfDayOfTheMonth', dayNumber
        $dayPicker.closest('[data-js-day-button]').attr('aria-label', "#{monthLabel} #{dayNumber}")
        if currentMoment.month() == beginningOfCalendarDate.getMonth() && currentMoment.date() == dayNumber
          $dayPicker.closest('td').addClass('today')
      else
        $dayPicker.closest('[data-js-day-button]').attr('aria-label', 'Day spacer')

  selectDate: (date) ->
    $('.timepicker__message__choose-date').hide()
    $('.timepicker__message').hide()
    $('.timepicker__date').removeClass('visibility-hidden')
    $('.datepicker').addClass('date-selected')
    @clearTimePickersContent()
    @markDayBlockAsSelected date
    @setHeaderOfTimePickerContentForDayBlock date
    @showAvailableBookingsForSelectedDay date
    $('[data-js-date-deselect]').prop('disabled', false).focus()

  deselectDate: () ->
    $('.datepicker').removeClass('date-selected')
    $('.timepicker__date').addClass('visibility-hidden')
    @showTimePickerHelpMessage()
    @clearTimePickersContent()
    @resetHeaderOfTimePickerContent()
    @markAllDayBlocksAsUnselected()
    @datepickerSquareTableCells()
    $('[data-js-date-deselect]').prop('disabled', true)

  enableDaysWithAvailability: (daysWithAvailability) ->
    @clearCalendarFromAvailableBookingsData()
    @deselectDate()
    if daysWithAvailability.length
      @showTimePickerHelpMessage()
    else
      @showUnavailableMonthMessage()

    for dayPicker in @dayPickers
      dayWithAvailability = daysWithAvailability.filter((availability) -> availability.day == $(dayPicker).data('numberOfDayOfTheMonth'))[0]
      $button = $(dayPicker).closest('[data-js-day-button]')
      if dayWithAvailability
        dayBlock = @dayBlockOfDay dayWithAvailability.day
        dayPartNames = dayWithAvailability.day_parts.map(dayPartName)
        for name in dayPartNames
          $(dayBlock).find(".datepicker__available.datepicker__available--#{name}").show()
        label = "#{$button.attr('aria-label')}. Availability: #{dayPartNames.join(', ')}"
        $button.closest('td').removeClass('disabled')
        $button.attr('aria-label', label).prop('disabled', false)
      else if $(dayPicker).data('numberOfDayOfTheMonth')
        label = "#{$button.attr('aria-label')}. Availability: none"
        $button.closest('td').addClass('disabled')
        $button.attr('aria-label', label).prop('disabled', true)
      else
        $button.prop('disabled', true)

    window.parent.postMessage("cliniko-bookings-resize:#{document.body.offsetHeight}", '*')

  refreshAvailableBookingsDataForDayBlock: (availableBookings, dayBlock) ->
    for dayPart, key in Bookings.Schedule.DAYPARTS
      availableBookingsInDayPart = groupObjectsByProperty(availableBookings, 'day_part')[key + 1]
      if availableBookingsInDayPart
        $(dayBlock).data(dayPart, availableBookingsInDayPart)
      else $(dayBlock).removeData(dayPart)

  dayBlockOfDay: (dayNumber)->
    dayPicker = @dayPickers.filter (index, dayPicker)=>
      $(dayPicker).data('numberOfDayOfTheMonth') == parseInt dayNumber, 10
    dayPicker.parents('td')

  dayNumberOfDayBlock: (dayBlock)->
     $(dayBlock).find('.datepicker__day__item__number').data 'numberOfDayOfTheMonth'

  dayOfMonth: (date) ->
    (date.getDay() - 1) %% 7

  dateStringOfDayBlock: (dayBlock)->
    [@displayedYear(), @pad(@displayedMonth()+1, 2), @pad(@dayNumberOfDayBlock(dayBlock),2)].join('-')

  setHeaderOfTimePickerContentForDayBlock: (dayBlock)->
    dayNumber = @dayNumberOfDayBlock dayBlock
    $('.timepicker__date__content').html("<strong>#{dayNumber}</strong><sup>#{@ordinalSuffixInDate dayNumber}</sup> #{@monthLabelOfBookingsTable @startDate}").addClass 'active'

  resetHeaderOfTimePickerContent: ->
   $('.timepicker__date__content').text('').removeClass 'active'

  clearCalendarFromAvailableBookingsData: ->
    @hideAvailableDayPartsIcons()
    @markCalendarDaysAsUnavailable()
    @dayBlocks.find('div').removeData()

  clearDayNumbers: ->
    @dayPickers.text('')
    @dayPickers.removeData()
    @dayBlocks.removeClass('today')

  clearTimePickersContent: ->
    @timePickerContent.text('')

  markDayBlockAsSelected: (dayBlock)->
    @markAllDayBlocksAsUnselected()
    $(dayBlock).addClass('selected')

  markAllDayBlocksAsUnselected: ->
    @dayBlocks.find('.selected').removeClass('selected')

  hideAvailableDayPartsIcons: ->
    for daypart in Bookings.Schedule.DAYPARTS
      $(".appointment__datepicker .datepicker__available.datepicker__available--#{daypart}").hide()

  markCalendarDaysAsUnavailable: ->
    @dayBlocks.addClass 'disabled'

  numberOfRequiredWeeks: (dateOfTheFirstDayOfMonth)->
    Math.ceil((@dayOfMonth(dateOfTheFirstDayOfMonth) + @numberOfDaysInMonth(dateOfTheFirstDayOfMonth))/7)

  adjustNumberOfDisplayedWeeks: (numberOfDisplayedWeeks)->
    weekRows = $('.datepicker__day tbody tr')
    weekRows.slice(0, numberOfDisplayedWeeks).removeClass('hidden')
    weekRows.slice(numberOfDisplayedWeeks).addClass('hidden')

  setMonthHeader: (date)->
    $('#datepicker__month .datepicker__month-option').text "#{@monthLabelOfCalendar date}"

  monthLabelOfBookingsTable: (date)->
    "<strong>#{Bookings.Schedule.MONTHLABELS[date.getMonth()]}</strong>, #{date.getFullYear()}"

  monthLabelOfCalendar: (date)->
    "#{Bookings.Schedule.MONTHLABELS[date.getMonth()]} #{date.getFullYear()}"

  numberOfDaysInMonth: (date)->
    beginningOfCurrentMonth = @beginningOfCurrentMonth date
    beginningOfnextMonth = @beginningOfNextMonth date
    numberOfDays = Math.round((beginningOfnextMonth - beginningOfCurrentMonth) / (1000 * 60 * 60 * 24))

  beginningOfCurrentMonth: (date)->
    beginningOfCurrentMonth = new Date date.getFullYear(), date.getMonth(), 1

  beginningOfNextMonth: (date)->
    beginningOfcurrentMonth = @beginningOfCurrentMonth date
    beginningOfnextMonth = beginningOfcurrentMonth.setMonth(date.getMonth() + 1)

  beginningOfPreviousMonth: (date)->
    beginningOfcurrentMonth = @beginningOfCurrentMonth date
    beginningOfpreviousMonth = beginningOfcurrentMonth.setMonth(date.getMonth() - 1)

  displayedYear: ->
    @startDate.getFullYear()

  displayedMonth: ->
    @startDate.getMonth()

  pad: (number, length)->
    str = '' + number
    while str.length < length
      str = '0' + str
    str

  formatTimeAmPm: (hours, minutes)->
    ampm = if hours < 12 then 'am' else 'pm'
    formattedHours = if hours == 0 or hours == 12 then '12' else "#{hours % 12}"
    formattedMinutes = @pad minutes, 2
    "#{formattedHours}:#{formattedMinutes}#{ampm}"

  ordinalSuffixInDate: (dayNumber)->
    value = parseInt dayNumber, 10
    if value > 10 && value < 14 then return 'th'
    switch value % 10
      when 1 then 'st'
      when 2 then 'nd'
      when 3 then 'rd'
      else 'th'

  datepickerSquareTableCells: ->
    $(".datepicker__day__item").css height: $(".datepicker__day td").width()

  showTimePickerHelpMessage: ->
    $('.timepicker__message').show()
    $('.timepicker__message__none-available').addClass('hidden')
    $('.timepicker__message__choose-date').show()

  showUnavailableMonthMessage: ->
    $('.timepicker__message').show()
    $('.timepicker__message__choose-date').hide()
    $('.timepicker__message__none-available').removeClass('hidden')
